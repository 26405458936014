<template>
<div>
  <div>
    <div class="content-header container-fluid bg-white">
      <div class="row">
        <div class="col-sm-4">
          <h4><i class="ti-direction-alt"></i> 工作流转 | 设置流程</h4>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-12">
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1" @page-changed="loadPage">
            <el-table-column prop="NAME" label="名称" width="200" />
            <el-table-column prop="DESCRIPTION" label="功能描述" width="500" />
            <el-table-column prop="CNT_STEP" label="总步骤" width="70" align="center" />
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-share" @click="flowEdit(scope.row)" >主要流程</el-button>
              </template>
            </el-table-column>
          </wgrid>
        </div>
      </div>
    </div>
  </div>
  <div><Flow ref="flow"/></div>
</div>
</template>

<script>
import Flow from "./flow";
import Wgrid from "@/components/wgrid";
export default {
  name: "index",
  components: {Wgrid, Flow},
  data(){
    return{
      grid:{
        sea:{
          PAGE_SIZE: 12,
          PAGE_INDEX: 0
        },
        ls:[],
        total:0,
        loading:false
      }
    }
  },
  created() {
    this.getList();
  },
  methods:{
    filter(){
      this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi){
      this.grid.sea.PAGE_INDEX = pi-1;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/WFL/WConfigApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls=its;
          self.grid.total=n;
          self.grid.loading=false;
        }
      })
    },
    flowEdit(r){
      this.$refs.flow.init(r);
    },
  }
}
</script>

