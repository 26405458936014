<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">流程配置</span>
      <div class="row">
        <div class="col-md-12">
          <el-button type="primary" @click="rowEdit({CONF_ID: grid.sea.CONF_ID})">新增步骤</el-button>
        </div>
        <div class="col-md-12">
          <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX+1">
            <el-table-column prop="STEP" label="步骤" width="60" align="center" />
            <el-table-column prop="PRO_TEA_NAME" label="操作人" width="200" />
            <el-table-column prop="ALT_TEA_NAME" label="提醒人" width="200" />
            <el-table-column prop="AMOUNT" label="金额" width="80" />
            <el-table-column label="操作" >
              <template slot-scope="scope">
                <el-button type="text" icon="el-icon-edit" @click="rowEdit(scope.row)" >修改</el-button>
                <el-button type="text" icon="el-icon-delete" @click="rowDel(scope.row)" >删除</el-button>
              </template>
            </el-table-column>
          </wgrid>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
      </div>
    </el-dialog>
    <div>
      <Edit ref="flow_edit" @on-saved="getList"/>
    </div>
  </div>
</template>
<script>
import Wgrid from "@/components/wgrid";
import Edit from "@/views/wfl/conf/edit";
export default {
  components: {Wgrid, Edit},
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      grid:{
        sea:{
          PAGE_SIZE: 0,
          PAGE_INDEX: 0,
          PARENT_ID:0
        },
        ls:[],
        total:0,
        loading:false
      }
    }
  },
  methods:{
    init(model){
      this.sta={show:true,loading:false};
      this.grid.sea.CONF_ID=model.ID;
      this.getList();
    },
    getList(){
      let self = this;
      this.grid.loading=true;
      this.whale.remote.getCollection({
        url: "/api/School/WFL/FlowApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls=its;
          self.grid.total=n;
          self.grid.loading=false;
        }
      })
    },
    rowEdit(r){
      this.$refs.flow_edit.init(r);
    },
    rowDel(r){
      this.whale.delConfirm(()=>{
        let self=this;
        this.whale.remote.getResult({
          url:"/api/School/WFL/FlowApi/Delete",
          data:{ID:r.ID},
          completed:function (){
            self.getList();
          }
        })
      });
    },
  }
}
</script>
