<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增/修改 记录</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>步骤</b></label><br />
            <el-input-number v-model="model.STEP" :min="1"/>
          </div>
          <div class="form-group">
            <label><b>金额/数量</b></label><br />
            <el-input-number v-model="model.AMOUNT" :min="0"/>
          </div>
          <div class="form-group">
            <label><b>处理人员</b></label><br />
            <el-select v-model="tea.sel_pro" multiple placeholder="请选择" style="width: 80%">
              <el-option
                  v-for="item in tea.ls"
                  :key="item.ID"
                  :label="item.NAME"
                  :value="item.ID">
              </el-option>
            </el-select>
          </div>
          <div class="form-group">
            <label><b>提醒人员</b></label><br />
            <el-select v-model="tea.sel_alt" multiple placeholder="请选择" style="width: 80%">
              <el-option
                  v-for="item in tea.ls"
                  :key="item.ID"
                  :label="item.NAME"
                  :value="item.ID">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return{
      sta:{
        show:false,
        loading:false
      },
      model: {STEP:1,AMOUNT:0},
      tea:{
        ls:[],
        sel_pro:[],
        sel_alt:[]
      }
    }
  },
  methods:{
    init(model){
      this.sta={show:true,loading:false}
      this.model=Object.assign({STEP:1,AMOUNT:0},JSON.parse((JSON.stringify(model))));
      let self=this;
      if (this.tea.ls.length==0){
        this.whale.remote.getCollection({
          url:"/api/School/ORG/TeacherApi/GetList",
          completed(its){
            self.tea.ls=its;

          }
        })
      }

      this.tea.sel_pro=[];
      this.tea.sel_alt=[];
      if (model.PRO_TEA_ID){
        let ia=model.PRO_TEA_ID.split(',');
        ia.map((it)=>{this.tea.sel_pro.push(parseInt(it))})
      }
      if (model.ALT_TEA_ID){
        let ia=model.ALT_TEA_ID.split(',');
        ia.map((it)=>{this.tea.sel_alt.push(parseInt(it))})
      }
    },
    submit(){
      this.model.PRO_TEA_ID=this.tea.sel_pro.join(',');
      this.model.ALT_TEA_ID=this.tea.sel_alt.join(',');
      let self=this;
      self.sta.loading=true;
      this.whale.remote.getResult({
        url:"/api/School/WFL/FlowApi/Save",
        data:self.model,
        finally(){self.sta.loading=false;},
        completed:function (){
          self.sta.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
